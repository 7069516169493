<template>
  <container-banner title="Les transports en commun">
    <h3>
      Un réseau de bus dense et étendu (SQYBUS)
    </h3>
    <p> 
      3 lignes à proximité :
      <ul class="my-3">
        <li>
          ligne 417A entre les gares de La Verrière et de Trappes
        </li>
        <li>
          ligne 402 - Mesnil St Denis - gare de la Verrière - gare de Trappes
        </li>
        <li>
          ligne 401, de Maurepas village à Versailles, en passant par la gare de St Quentin
        </li>
      </ul>
      Plus d'informations sur le site de SQYBus : 
        <a 
          class="extern-link" 
          href="https://www.sqybus.fr/"
          target="_blank"
        >
          https://www.sqybus.fr/
        </a>
    </p>
    <h3 class="pt-8">
      Le réseau ferré (SNCF Ile-de-France)
    </h3>
    <p>
      3 gares qui desservent Paris Montparnasse, La Défense et Rambouillet :
      <ul class="my-3">
        <li>
          La Verrière (10 à 15 mn en bus)
        </li>
        <li>
          Trappes (15 à 20 mn en bus)
        </li>
        <li>
          Saint Quentin (20 à 25 mn en bus) avec en plus le RER C vers Paris, ou Massy-Palaiseau via Versailles
        </li>
      </ul>
      A noter aussi, une gare TGV à Massy<br>
      Plus d'informations sur le site de la SNCF Ile de France : 
        <a 
          class="extern-link" 
          href="https://www.transilien.com/"
          target="_blank"
        >
          https://www.transilien.com/
        </a>
    </p>
  </container-banner>
</template>

<script>
import ContainerBanner from '@/components/ContainerBanner.vue'

export default {
  name: 'Transports',
  components: {
    ContainerBanner
  }
}
</script>