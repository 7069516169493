<template>
  <container-banner title="Collecte des déchets & déchetteries">
    <p class="mb-8">
      La collecte des déchets ménagers est à la charge de Saint-Quentin-en-Yvelines (SQY). Le calendrier des collectes est revus annuellement et change à partir du 1er janvier de chaque année.
    </p>
    <p class="mb-12">
      Pour télécharger le calendrier de collecte de l'année en cours :<br>
      <span class="d-flex mt-4">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link"
          href="https://elancourt.fr/files/Documents/ELA-calendrier-collecte-2024.pdf" 
          download="Calendrier-des-collectes.pdf"
          target="_blank"
        >
          Calendrier des collectes de déchets
        </a>
      </span>
    </p>
    <p class="mb-8">
      Concernant les déchetteries, SQY mets à votre disposition 7 déchetteries accessibles avec la carte DECHETS.<br>
      Si vous ne possédez pas encore cette carte, vous pouvez en faire la demande directement depuis le site internet de SQY via ce formulaire : <br>
      <span class="d-flex mt-4">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link"
          href="https://peps.sqy.fr/e-services/demandes-dechetterie/Pages/demande-de-carte-dechet.aspx"
          target="_blank"
        >
          Lien vers le formulaire de demande de la carte DECHETS
        </a> 
      </span>
    </p>
    <p>
      Vous pouvez également consulter les horaires des différentes déchetteries depuis le site internet de SQY :<br>
      <span class="d-flex mt-4">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link"
          href="https://www.saint-quentin-en-yvelines.fr/fr/services-et-vie-pratique/gerer-ses-dechets/localisation-et-horaires-des-dechetteries-et-des-points-d-apports-volontaires-pav"
          target="_blank"
        >
          Lien vers les horaires des différentes déchetteries
        </a>
      </span>
    </p>
  </container-banner>
</template>

<script>
import ContainerBanner from '@/components/ContainerBanner.vue'

export default {
  name: 'Dechets',
  components: {
    ContainerBanner
  }
}
</script>