<template>
  <container-banner title="Loisirs & Sports">
    <div
      class="d-flex flex-wrap justify-space-around"
    >
      <base-card
        title="Médiathèque des 7 mares"
        link="https://e-mediatheque.sqy.fr/Default/mediatheque-des-7-mares.aspx"
        text="L'agglomération de SQY dispose d'un réseau de médiathèques dont la médiathèque des 7 Mares située à Elancourt."
        filename="mediatheque.jpg"
      />
      <base-card
        title="Spectacles"
        link="https://www.saint-quentin-en-yvelines.fr/fr/sortir-et-decouvrir/envie-de-culture/les-salles-de-spectacles-sqy"
        text="Elancourt et plus largement l'agglomération de Saint Quentin en Yvelines disposent de nombreuses salles de spectacles."
        filename="spectacle.jpg"
      />
      <base-card
        title="Cinéma"
        link="https://cinesept.wixsite.com/cinesept"
        text="Le ciné 7, cinéma de Elancourt, situé proche de la Mairie, dispose de 3 salles et saura ravir petits et grands."
        filename="cinema.jpg"
      />
      <base-card
        title="Parc La France miniature"
        link="https://www.franceminiature.fr/fr"
        text="France miniature est situé à Elancourt, vous pouvez visiter en version miniature, les monuments français."
        filename="f-miniature.jpg"
      />
      <base-card
        title="L'ile de Loisirs"
        link="https://saint-quentin-en-yvelines.iledeloisirs.fr/"
        text="L’île de loisirs de Saint-Quentin-en-Yvelines propose nombreuses activités ludiques, pédagogiques et sportives."
        filename="ile-loisirs.jpg"
      />
      <base-card
        title="Les espaces verts"
        link="https://elancourt.fr/mes-loisirs/squares-et-espaces-verts/baladez-vous-a-elancourt"
        text="Pour de belles ballades, Elancourt dispose d'un ensemble d'espaces verts dont certains qui passe derrière la résidence."
        filename="espaces-verts.jpg"
      />
    </div>
  </container-banner>
</template>

<script>
import ContainerBanner from "@/components/ContainerBanner.vue";
import BaseCard from "@/components/BaseCard.vue";

export default {
  name: "Loisirs",
  components: {
    ContainerBanner,
    BaseCard,
  },
};
</script>
