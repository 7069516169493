<template>
  <div 
    class="about-container"
    :class="
        $vuetify.breakpoint.smAndUp
          ? 'pa-12'
          : 'pa-6'"
    >
    <section>
      <h2>Qu'est-ce qu'une AFUL ?</h2>
      <p>
         C'est une Association Foncière Urbaine Libre, régie par l'ordonnance n°2004-632 du 1er juillet 2004 et par le décret n°2006-504 du 3 mai 2006. Vous pourrez consulter ces textes sur le site de 
         <a class="extern-link" href="https://www.legifrance.gouv.fr/rechSarde.do">Légifrance</a>, avec le thème : Association syndicale de propriétaires.
      </p>
      <p>
        Une AFUL n’est ni une association de loi 1901, ni un conseil syndical de copropriété. Elle n’est donc pas soumise ni à la loi de 1901 sur les associations, ni à celle de 1965 sur la copropriété. Elle fonctionne selon ses propres règles définies par ses statuts.
      </p>
    </section>
    <section>
      <h2>
        Historique des rues de la Commanderie des Templiers 2 et de son logo (sceau des Templiers).
      </h2>
      <span><small>— de A. Hawecker</small></span>
      <article>
        <h3>Les rues de la résidence</h3>
        <p>
          Les noms des rues de la résidence sont, pour la plupart, des patronymes de grand Maître de l’Ordre des Templiers. Noblesse oblige, nous allons commencer par le fondateur de l’ordre, Hugues de Payns, qui a donné son nom à la rue jouxtant le tennis.
        </p>
      </article>
      <article>
        <h3>La rue de Payns</h3>
        <p>
          Hugues de Payns (1080-1136) possédait, en tant que seigneur de Montigny, la terre de Payns à une dizaine de kilomètres de TROYES ainsi qu’un fief près de TONNERRE. Il aurait été étroitement lié à la puissante famille des comtes de Champagne et c’est d’ailleurs avec Hugues de Champagne qu’il effectua son premier pèlerinage en Terre sainte en 1104. Il fit plusieurs allées et venues entre la France et la Syrie avant de prononcer les vœux définitifs en 1119. C’est à JERUSALEM qu’il eut l’idée de fonder, en 1118, un ordre de moines-soldats dont l’appellation était l’Ordre des pauvres chevaliers du Christ et ayant comme signe distinctif une croix pattée rouge. Les moines-soldats avaient pour mission de défendre les pèlerins et de protéger les chemins menant en Terre sainte. En 1126-27, Hugues de Payns entreprit des démarches auprès du pape Honorius III et de Bernard de Clairvaux pour obtenir confirmation de son Ordre et un statut. C’est sous l’égide de ce dernier que s’est tenu le concile de TROYES, le 13 janvier 1128, qui donna entière satisfaction à de Payns. Le concile approuvera la naissance de l’Ordre des Templiers et lui donnera comme objectif la défense de la Terre sainte. Les Templiers devaient seulement obéissance au pape et au grand Maître. Jusqu’à sa mort, en 1136, le grand Maître Hugues de Payns n’eut de cesse à développer l’Ordre en Orient comme en Occident.
        </p>
      </article>
      <article>
        <h3>La rue de Tramelay</h3>
        <p>
          La rue de Tramelay doit son nom au quatrième grand Maître des Templiers, Bernard de Tramelay, originaire de la Franche Comté. Son nom est parfois orthographié Dramelai ou encore Tramelai. Il n’a dirigé l’Ordre que de 1150 à 1153.
        </p>
        <p>
          Dès son élection, il s’est attaché à fortifier les principales places fortes de l’Ordre en Terre sainte, et particulièrement les cités côtières, indispensables à la survie du royaume de Jérusalem. La domination de ces places était aussi un impératif pour les Turcs et les Egyptiens et nombres de combats se déroulèrent pour leur possession. La ville portuaire d’Ascallon passa ainsi d’un camp à l’autre.
        </p>
        <p>
          En 1153, les Turcs tenaient Ascallon et le roi de Jérusalem, Baudouin III, décidait de la reprendre. Le 16 août, quarante Templiers réussissaient à s’introduire dans la ville, mais Bernard de Tramelay fut tué en tentant de les rejoindre. On prétend que sa hâte à entrer le premier dans la cité venait de son désir de soustraire un maximum de butin aux Hospitaliers qui participaient également à l’assaut.

          Bernard de Tramelay a été le premier grand Maître mort au combat.
        </p>
      </article>
      <article>
        <h3>La rue de Ridefort</h3>
        <p>
          La rue doit son nom à Gérard de Ridefort qui fut le dixième Grand Maître de l’Ordre des Templiers, très contesté d’ailleurs, de 1185 à 1190.
        </p>
        <p>
          Flamand de petite noblesse, le chevalier de Ridefort se rend en Terre sainte pour chercher bonne ou mauvaise fortune. Il se met au service du comte Raymond de Tripoli avant de vouer à ce dernier une haine farouche pour une banale histoire de dot. Malade et abandonné, il est recueilli et soigné par les Templiers à Jérusalem. Il décide alors de s’engager dans l’Ordre en 1181 où il fait une carrière fulgurante pour être nommé sénéchal en 1183 et Grand Maître le 4 octobre 1185.
        </p>
        <p>
          Un an plus tard, à la mort de Baudouin V, éclate en Terre sainte une dure lutte de succession entre Gui de Lusignan et Raymond de Tripoli. De Ridefort soutient tout naturellement Gui qui s’avère pourtant être un incapable. Ce dernier étant couronné, le royaume est fragilisé et subit de nombreuses défaites.
        </p>
        <p>
          A Casal-Robert, les Mamelouks écrasent l’armée des Francs et parmi les cent quarante Templiers tués on relève un seul rescapé: Gérard de Ridefort. Plus tard, les trente mille hommes de Gui sont défaits par les guerriers de Saladin pendant une bataille menée sur les conseils de Gérard de Ridefort. Là encore, il fut le seul survivant des deux cent trente Templiers engagés. On l’accuse de s’être converti à l’islam après qu’il ait demandé aux cités de Gaza et d’Ascalon de se rendre, sans combat, à Saladin. Peu après, Saladin s’empare de Jérusalem et transforme en mosquée la maison des Templiers. Il continue de pourchasser les Templiers qu’il veut exterminer. Gérard de Ridefort meurt en 1190 en combattant sous les murs de Saint Jean d’Acre. Il règne ensuite un tel malaise dans l’Ordre que la maîtrise reste vacante pendant dix mois avant la nomination de son successeur Robert de Sablé.
        </p>
      </article>
      <article>
        <h3>L’avenue de Beaujeu</h3>
        <p>
          La rue doit son nom à Guillaume de Beaujeu qui fut le vingtième Grand Maître de l’Ordre des Templiers de 1273 à 1291.
        </p>
        <p>
          Il appartenait à une lignée prestigieuse : cousin de Charles d’Anjou, roi de Sicile; Louis, son frère, fut connétable du royaume de France. La famille était issue du Beaujolais et Guillaume de Beaujeu fut parrain d’une des filles de Louis IX.
        </p>
        <p>
          Grand seigneur, il s’acquit rapidement la réputation d’un homme courageux, mais méprisant et orgueilleux. Sans faiblesse, il demeura intransigeant pour tout ce qui touchait à l’intégrité d’un Ordre dont il connaissait parfaitement la vocation initiatique.
        </p>
        <p>
          Commandeur de la Pouille, il était en Terre Sainte lorsqu’il fut assiégé dans St Jean d’Acre. Son héroïsme lui a valu le commandement de toutes les défenses. Lors de la chute de cette ville, il fut tué en prononçant ces mots: "Seigneur, je ne peux plus car je suis mort, voyez le coup".
        </p>
        <p>
          Il fut le dernier maître de l’Ordre en Orient. Son successeur, Thibaud Gaudin, organisa le repli des Templiers sur l’île de Chypre avant d’abandonner son titre à Jacques de Molay en 1293.
        </p>
      </article>
      <article>
        <h3>La rue de Périgord</h3>
        <p>
          La rue doit son nom à Armand de Périgord, Grand Maître de l’Ordre des Templiers de 1232 à 1244.
        </p>
        <p>
          Membre de la famille des comtes de Périgord, il remplit, avant d’être Maître, la charge de précepteur de Sicile et de Calabre et commença sa carrière au plus haut niveau en faisant restaurer le château de Safita en Syrie, ruiné par les musulmans en 1219.
        </p>
        <p>
          Il dut faire face aux incessantes querelles qui opposaient les Templiers aux autres ordres de moines-soldats. D’abord avec les chevaliers Teutoniques qui affichaient de plus en plus leurs tendances pro-germaniques, puis avec les Hospitaliers qui souhaitaient une alliance avec le Caire alors que les Templiers avaient opté pour Damas. La croisade menée à la demande du pape Grégoire IX par les Templiers et les Hospitaliers, réconciliés sous la contrainte par Thibaud de Champagne, a abouti à la restitution d’Ascalon par les Egyptiens et à la rétrocession au royaume de Jérusalem de Tibériade et de la Galilée par Damas.
        </p>
        <p>
          Mais début octobre 1244, les Turcs Kharismiens reprennent l’offensive et s’installent à Gaza. La rencontre avec les croisés sera un désastre: trois cents Templiers tombent avec leur Maître Armand de Périgord sous les murs de la ville.
        </p>
      </article>
      <article>
        <h3>La rue de Sonnal</h3>
        <p>
          La rue de Sonnal doit son nom à Guillaume de Sonnac (et non pas Sonnal) qui succéda à Armand de Périgord en 1224. Il fut donc le 17ème grand Maître de l’Ordre des Templiers jusqu’en 1250. Ce personnage était en tous points exceptionnel. Avisé, prudent, il a excellé à la fois dans l’art de la diplomatie et dans celui des armes. Il a si bien négocié avec ses adversaires que se faisait jour dans l’opinion publique l’idée que les Templiers fraternisaient avec les musulmans. Il est vrai qu’il était en bons termes avec le sultan du Caire El Ayoub.
        </p>
        <p>
          Guillaume de Sonnac était né dans le Rouergue. Devenu très tôt Templier, il occupa la charge de Maître de la province de Pouilles. Il fit réorganiser les archives de l’Ordre et les fit mettre en lieu sûr.
        </p>
        <p>
          Le règne de Guillaume de Sonnac s’acheva tragiquement lors de la première et catastrophique croisade de Saint Louis (Louis IX). Il sauva à Damiette le souverain français, mais ne put éviter, le 3 juillet 1250, la folle équipée qui se solda par le désastre de la Mansourah où Saint Louis fut fait prisonnier et lui-même perdit la vie avec deux cents Templiers.
        </p>
      </article>
      <article>
        <h3>La place de la Mesmie</h3>
        <p>
          Les recherches pour trouver l’origine de l’appellation de la place de la Mesmie n’ont pas été aisées car ce nom, avec cette orthographe, ne figure dans aucun texte des Templiers. Toutefois, on relève le terme Mesnie dans certains documents.
        </p>
        <p>
          La Mesnie est une escouade composée de neuf soldats-Templiers chargée de la protection rapprochée du grand Maître de l’Ordre ou de la garde de l’étendard "le Beauséant".
        </p>
        <p>
          La Mesmie est très certainement une écriture erronée de Mesnie.
        </p>
      </article>
      <article>
        <h3>L’avenue de la Baylie</h3>
        <p>
          La baylie (bailliage) était la circonscription administrative soumise à la juridiction d’une Commanderie. Ces terres provenaient souvent de dons offerts aux Templiers par les seigneurs voisins. Le Sénéchal (bailli) directement placé sous l’autorité du Commandeur (à la tête de la Commanderie et donc de la baylie), était chargé de la gestion administrative, fiscale et judiciaire des biens de ce domaine. Sous les ordres du Sénéchal, le receveur levait les impôts et des soldats-Templiers y faisaient respecter la loi.
        </p>
      </article>
      <article>
        <h3>L’avenue de Beauséant</h3>
        <p>
          L’avenue de Beauséant a pris le nom de l’étendard de guerre des Templiers. Cet étendard, le Beauséant, est composé de 2 bandes superposées, blanche et noire, et se termine par 2 pointes. Le blanc, aussi appelé "argent", se rapporte au monde de l’esprit et le noir ou "sable" représente la matérialité. Les deux couleurs associées ou plutôt affrontées évoquent le combat de l’esprit contre la matière ce qui, pour les Templiers, entre dans la conception logique de la guerre. "A moi beau sire ! Beauséant à la rescousse" était le cri de guerre des moines-soldats.
        </p>
        <div 
          class="d-flex mb-4"
          :class=" $vuetify.breakpoint.xsOnly
              ? 'flex-column'
              : ''
          "
        >
          <v-img
            contain
            lazy-src="@/assets/images/beauseant.jpeg"
            src="@/assets/images/beauseant.jpeg"
            alt="Image d'illustration de l'étandard de Beauséant"
            max-height="150"
            max-width="auto"
          />
          <p 
            :class=" $vuetify.breakpoint.xsOnly
              ? 'ml-0 mt-4'
              : 'ml-4 mt-2'
          ">
            En campagne, le Beauséant flottait sur la tente du grand Maître qui en avait la charge. Pendant les combats, il était brandi par un chevalier, appelé gonfanonier, entouré de 9 Templiers qui avaient le devoir de le protéger. L’étendard devait toujours être pointé en direction du ciel et servait de point de ralliement aux guerriers. L’abaisser ou le laisser choir à terre était considéré comme une faute majeure et le coupable s’exposait à une lourde sanction.
          </p>
        </div>
         <p>
            A noter que l’orthographe de beauséant est fluctuante selon les historiens : beausséant, beaucéant ou baucent. La signification de ce nom n’est pas vraiment établie. Au moyen âge, l’adjectif beau avait un sens plus fier comme noble ou glorieux et le séant correspondait à l’arrière, à l’envers des apparences. Baucent serait la déformation de "vaut cent" pour rappeler le courage du Templier.
          </p>
      </article>
      <article>
        <h3>Une comparaison ...</h3>
        <p>
          Notre résidence est gérée par une association foncière créée par une loi datant de 1865. On peut s’étonner de l’ancienneté de cette loi, mais ce qui est encore plus remarquable et amusant, c’est que notre forme d’association foncière peut trouver son origine dans des temps plus reculés encore.
        </p>
        <p>
          En effet, les villages ne sont dirigés par un conseil municipal élu que depuis 1790, au lendemain de la révolution. Avant cette date, les habitants d’un village se regroupaient dans une "communauté d’habitants" (AFUL)*, association de tous les chefs de maison (propriétaires)* pour défendre leurs intérêts propres, souvent en concurrence avec le seigneur local.
        </p>
        <p>
          Ne disposant pas de salle, les communautés (AFUL)* se réunissaient dans l’église et plus souvent encore sous le porche, à l’issue de la messe paroissiale. Ces réunions (Assemblées générales)* qui avaient lieu une ou plusieurs fois par an regroupaient en théorie tous les chefs de feux (propriétaires d’une maison), mais l’assistance n’était pas obligatoire. On écartait de l’assemblée ceux qui ne payaient l’impôt (cotisation)* et ceux qui n’étaient pas propriétaires, en particulier les métayers.
        </p>
        <p>
          La "communauté" élisait un chef (président)*, souvent appelé le syndic, parfois l’échevin ou le procureur. Il pouvait être assisté de plusieurs autres membres de la "communauté" pour former une sorte de Conseil (comité syndical)*. Les délibérations concernaient la fixation et la répartition des impôts (cotisations)* . Car il fallait payer la taille (impôts fonciers)* au roi, disposer d’un budget pour rétribuer le berger communal (jardinier)* et effectuer quelques réparations (entretien de la résidence)*.
        </p>
        <p>
          Le plus délicat était de gérer les biens communs (parties à usage collectif)* : prairies, forêt, lavoir et four.
        </p>
        <p>
          ( )* les termes entre parenthèses vous permettent de faire la comparaison avec notre type d’organisation et de gestion de la résidence.
        </p>
      </article>
    </section>
    <section>
      <v-row class="mb-5">
        <v-col cols="2">
          <v-img
            contain
            lazy-src="@/assets/images/sceau.jpeg"
            src="@/assets/images/sceau.jpeg"
            alt="image d'illustration du Logo de la Commanderie des Templiers 2"
          />
        </v-col>
        <v-col cols="10" class="d-flex align-center">
          <h2>Le logo</h2>
        </v-col>
      </v-row>
      <article>
        <p>
          En mai 1995, à l’initiative du secrétaire de l’Aful 2, M. CÊTRE, l’en-tête du bulletin intérieur a été agrémenté d’une reproduction d’un sceau de l’Ordre des Templiers. Depuis, le comité de l’Aful 2 a adopté cette représentation graphique comme logo et l’utilise dans toutes ses correspondances.
        </p>
        <p>
          Voici quelques renseignements sur le sceau des Templiers:
          <ul>
            <li>
              il s’agit d’un des premiers sceaux de l’Ordre (XIIème siècle) qui représente 2 chevaliers-Templiers, la lance en arrêt, poussant leur unique cheval contre l’adversaire
            </li>
            <li>
              le sceau, appelé par la suite "boule", était coulé en argent et en plomb et portait l’inscription "SIGILLVM MILITVM XPISTI" (sceau des soldats du Christ)
            </li>
            <li>
              il est probable que les cavaliers qui y figurent soient Hugues de Payns (fondateur de l’Ordre) et Godefroi de Saint Omer, faisant la police aux Lieux-Saints au début de leur association (1119)
            </li>
          </ul>
        </p>
        <p>
          Le fait de représenter deux Templiers sur le même cheval a eu plusieurs interprétations dont :
          <ul>
            <li>
              l’obligation de monter à deux faute de montures, (c’est l’hypothèse la plus vraisemblable car, à la création de l’Ordre, les Templiers manquaient de moyens financiers)
            </li>
            <li>
              un symbole du manichéisme, religion alliant à un fonds chrétien des principes du bouddhisme et pour laquelle le Bien et le Mal sont deux principes égaux et antagonistes
            </li>
            <li>
              une allusion à la loi binaire, les Templiers ayant été initiés aux doctrines pythagoriciennes
            </li>
          </ul>
        </p>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped>
.about-container {
  font-family: source sans pro, sans-serif !important;
}
.extern-link {
  color: #1abc9c;
  font-weight: 600;
}
h2 {
  color: #444;
  font-weight: 600;
  line-height: 1.5;
  font-size: 25px;
  padding-bottom: 30px;
}
h3 {
  color: #444;
  font-weight: 600;
  line-height: 1.5;
  font-size: 20px;
  padding: 30px 0;
}
section {
  margin-bottom: 50px;
}
p {
  text-align: justify;
}
</style>
