<template>
  <v-app id="app">
    <v-main>
      <v-container 
        fluid 
        class="pa-0 ma-0"
      >
        <component :is="layout">
          <router-view />
        </component>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const LayoutFull = () => import('@/layouts/LayoutFull.vue')
const LayoutDefault = () => import('@/layouts/LayoutDefault.vue')

export default {
  name: 'App',
  components: {
    LayoutFull,
    LayoutDefault,
  },
  computed: {
    layout() {
      if (!this.$route.meta.layout) return 'layout-default'
      return `layout-${this.$route.meta.layout}`
    },
  }
};
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100%;
}
</style>
