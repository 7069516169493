<template>
  <container-banner
    title="Quelques liens utiles concernant la région et autres"
  >
    <v-row>
      <v-col>
        <h3 class="ml-5 mb-3">
          Aides et démarches
        </h3>
        <div
          v-if="$vuetify.breakpoint.xsOnly"
          class="d-flex flex-column justify-center align-center"
        >
          <v-card
            v-for="(link, i) in linksList"
            :key="i"
            class="d-flex flex-column align-center justify-center ma-4"
            flat
            color="white"
            height="auto"
            width="100%"
          >
            <span
              class="link-name text-center mt-5"
            >
              {{ link.name }}
            </span>
            <v-img
              height="187.5"
              width="250"
              contain
              :src="link.src"
              :alt="link.alt"
            />
            <v-card-text
              class="d-flex justify-center pr-0 mb-5"
            >
              <div
                class="d-flex align-center justify-center text-center"
              >
                <a
                  :href="link.url"
                  class="link-url mt-0"
                  target="_blank"
                >
                  {{ link.url }}
                </a>
                <v-icon color="#1ABC9C"
                  >mdi-menu-right</v-icon
                >
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div
          v-else
          class="d-flex"
          :class="
            $vuetify.breakpoint.lgAndUp
              ? 'flex-wrap'
              : 'flex-column'
          "
        >
          <v-card
            v-for="(link, i) in linksList"
            :key="i"
            class="px-4 d-flex align-center justify-center"
            flat
            color="#F9F9F9"
            height="187.5"
            :width="
              $vuetify.breakpoint.lgAndUp
                ? '50%'
                : '100%'
            "
          >
            <v-img
              height="187.5"
              width="250"
              contain
              :src="link.src"
              :alt="link.alt"
            />
            <v-card-text
              class="d-flex flex-column justify-center pr-0"
            >
              <span class="link-name">{{
                link.name
              }}</span>
              <div class="d-flex align-end">
                <a
                  :href="link.url"
                  class="link-url"
                  target="_blank"
                >
                  {{ link.url }}
                </a>
                <v-icon color="#1ABC9C"
                  >mdi-menu-right</v-icon
                >
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-row no gutters class="ma-0 pb-8">
      <v-col>
        <h3 class="ml-5 mb-5">
          Numéros d'urgence
        </h3>
        <div>
          <ul>
            <li class="d-flex">
              <h4>SAMU :</h4>
              <span>15</span>
            </li>
            <li class="d-flex">
              <h4>Pompiers :</h4>
              <span>18</span>
            </li>
            <li class="d-flex">
              <h4>Gendarmerie :</h4>
              <span>17</span>
            </li>
            <li class="d-flex">
              <h4>N° Européen sur Mobile :</h4>
              <span>112</span>
            </li>
            <li class="d-flex">
              <h4>Centre Anti Poison :</h4>
              <span> 01 40 05 48 48</span>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </container-banner>
</template>

<script>
import ContainerBanner from "@/components/ContainerBanner.vue";

export default {
  name: "Infos",
  components: {
    ContainerBanner,
  },
  data() {
    return {
      linksList: [
        {
          src: "./images/mairie-elancourt.jpg",
          name: "Mairie d'elancourt :",
          url: "https://elancourt.fr/",
          alt: "image d'illustration du site internet de la mairie d'Elancourt",
        },
        {
          src: "./images/conseil-general.jpg",
          name: "Conseil général des Yvelines :",
          url: "https://www.yvelines.fr/",
          alt: "image d'illustration du site internet du Conseil Général des Yvelines",
        },
        {
          src: "./images/prefecture.jpg",
          name: "Préfecture des Yvelines :",
          url: "https://www.yvelines.gouv.fr/",
          alt: "image d'illustration du site internet de la préfecture",
        },
        {
          src: "./images/sqy.jpg",
          name: "Agglomération de Saint-Quentin-en-Yvelines :",
          url: " https://www.saint-quentin-en-yvelines.fr/fr",
          alt: "image d'illustration du site internet de l'Agglomération de Saint-Quentin-en-Yvelines",
        },
        {
          src: "./images/sqy-bus.jpg",
          name: "Réseau de bus de Saint-Quentin-en-Yvelines :",
          url: "https://www.sqybus.fr/",
          alt: "image d'illustration du site internet du Réseau de bus de Saint-Quentin-en-Yvelines",
        },
        {
          src: "./images/transilien.jpg",
          name: "SNCF Île-de-France :",
          url: "https://www.transilien.com/",
          alt: "image d'illustration du site internet de la SNCF Île-de-France",
        },
        {
          src: "./images/meteofrance.jpg",
          name: "Météo France :",
          url: "https://meteofrance.com/",
          alt: "image d'illustration du site internet de Météo France",
        },
        {
          src: "./images/service-public.jpg",
          name: "Le portail du service public :",
          url: " https://www.service-public.fr/",
          alt: "image d'illustration du site internet du portail du service public",
        },
      ],
    };
  },
};
</script>

<style scoped>
h4 {
  font-family: source sans pro, sans-serif !important;
  font-weight: 600;
  color: #333;
  font-size: 16px;
  margin-right: 3px;
}
.link-name {
  font-size: 22px;
  color: #333;
  font-weight: 400 !important;
  font-family: source sans pro, sans-serif !important;
  overflow-wrap: normal;
}
.link-url {
  color: #1abc9c;
  padding: 0 2px;
  line-height: 1.5;
  font-family: source sans pro, sans-serif !important;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
}
</style>
