<template>
  <container-banner title="Les Commerces & marchés à proximité">
    <h3>
      Les commerces
    </h3>
    <p>
      Un petit centre commercial est accessible à 5 ou 10 minutes à pied,  avec supérette, pharmacie, boulangerie, boucherie , presse, banques, coiffeur, agence de voyages – restaurants, etc.
    </p>
    <p>
      Le centre ville d'Elancourt se trouve à 5 minutes en voiture. On y trouve bureau de poste, mairie, commissariat de police, supermarché et une vingtaine de commerces.
    </p>
    <p>
      A 10 minutes en voiture, la zone commerciale N10 Coignières-Maurepas, est installée le long de la nationale 10 qui relie Saint Quentin en Yvelines à Rambouillet. 
      Elle réunit plusieurs centres commerciaux :
      <ul class="mt-3">
        <li>
          le centre commercial Gibet à Coignières
        </li>
        <li>
          le centre commercial Porte de Chevreuse
        </li>
        <li>
          le centre commercial Paris West à Maurepas
        </li>
      </ul>
    </p>
    <p>
      A moins de 15 minutes en voiture, on retrouve également le Centre Commercial Espace Saint Quentin, situé à Montigny le Bretonneux. Celui-ci regroupe 130 magasins, restaurants et services.
    </p>
    <p>
      Egalement à proximité, dans la ville de Plaisir, nous avons le nouveau centre commercial "Mon grand plaisir" le centre commercial open sky de l'ouest parisien.
    </p>
    <h3 class="pt-8">
      Les marchés les plus proches de Élancourt
    </h3>
    <p>
      <ul>
        <li>
          A Maurepas : le mercredi, samedi de 8h à 13h. Place Ribout 78310 Maurepas
        </li>
        <li>
          A Trappes : le mardi, vendredi, samedi de 8h à 13h. Place des Merisiers 78190 Trappes
        </li>
        <li>
          Toujours à Trappes : le dimanche de 8h à 12h. Place Carnot 78190 Trappes
        </li>
        <li>
          A Montigny-le-Bretonneux : le mardi, vendredi, dimanche, de 15h à 19h. Place Ribout 78310 Maurepas
        </li>
      </ul>
    </p>
  </container-banner>
</template>

<script>
import ContainerBanner from '@/components/ContainerBanner.vue'

export default {
  name: 'Commerces',
  components: {
    ContainerBanner
  }
}
</script>