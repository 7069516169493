<template>
  <div>
    <v-row class="banner ma-0" no gutters>
      <v-col class="text-center">
        <h2 class="banner-title">
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-12">
        <slot />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ContainerBanner',
  props: {
    title: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>
  .banner {
    background-color: #FFFFFF;
  }
  .banner-title {
    margin: 40px 0;
    font-size: 30px;
    color: #444;
    line-height: 1.5;
    font-family: source sans pro, sans-serif !important;
    font-weight: 400;
  }
  p {
    font-family: source sans pro, sans-serif !important;
    font-size: 18px;
    text-align: justify;
  }
  h3 {
    font-size: 24px;
    color: #555;
    font-family: source sans pro, sans-serif !important;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 3rem;
    letter-spacing: 1px;
  }
  .extern-link {
    color: #1abc9c;
    font-weight: 600;
  }
</style>