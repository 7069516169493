import Vue from 'vue'
import VueRouter from 'vue-router'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', 
    redirect: 'home'
    },
    { 
      path: '*', 
      redirect: 'error-404' 
    },
    ...pages,
  ]
})

export default router
