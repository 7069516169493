import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

/*
 * Icons Custom
 */
import ArrowDown from "@/components/icons/ArrowDown.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      arrowDown: {
        component: ArrowDown,
      },
    }
  }
});
