<template>
  <v-sheet color="transparent">
    <v-row no-gutters>
      <v-col class="pa-0">
        <div class="container-accueil pa-0 ma-0">
          <h2
            :class="
              $vuetify.breakpoint.smAndUp
                ? 'title-animation-desktop'
                : 'title-animation-mobile'
            "
            id="title"
            class="title-animation"
          >
            <span class="first-word">15 hectares.</span>
            <span>Résidence privée.</span>
            <span>Style architectural.</span>
            <span>Espaces verts.</span>
          </h2>

          <video-player v-if="$vuetify.breakpoint.mdAndUp" class="home-video" />

          <v-img
            v-else
            class="home-image"
            src="@/assets/images/photo-1.jpg"
            lazy-src="@/assets/images/photo-1.jpg"
            alt="Photo d'illustration de la Commanderie des Templiers 2"
          />
          <a
            href="#home-infos"
            class="animated fadeInDown home-arrow"
            :class="
              $vuetify.breakpoint.mdAndUp ? 'arrow-desktop' : 'arrow-mobile'
            "
          >
            <v-icon large>$arrowDown</v-icon>
            <div>Suite</div>
          </a>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="$vuetify.breakpoint.smAndUp"
      no-gutters
      id="home-infos"
      class="px-8 pb-4 pt-8"
    >
      <v-col class="d-flex text-justify align-center home-text" cols="7">
        <p>
          Une belle résidence privée à Élancourt : La Commanderie des Templiers
          II entre Versailles et Rambouillet, dans la ville nouvelle de
          Saint-Quentin-en-Yvelines, un bel ensemble de pavillons avec un style
          architectural remarquable et agrémenté de nombreux espaces verts.
        </p>
      </v-col>
      <v-col class="pl-12 d-flex justify-end" cols="5">
        <v-img
          src="@/assets/images/photo-2.jpg"
          lazy-src="@/assets/images/photo-2.jpg"
          alt="photo de la commanderie des templiers 2"
          height="300"
          contain
        />
      </v-col>
    </v-row>
    <div v-else class="d-flex flex-column ma-6">
      <v-img
        src="@/assets/images/photo-2.jpg"
        lazy-src="@/assets/images/photo-2.jpg"
        alt="photo de la commanderie des templiers 2"
        height="300"
        contain
      />
      <p>
        Une belle résidence privée à Élancourt : La Commanderie des Templiers II
        entre Versailles et Rambouillet, dans la ville nouvelle de
        Saint-Quentin-en-Yvelines, un bel ensemble de pavillons avec un style
        architectural remarquable et agrémenté de nombreux espaces verts.
      </p>
    </div>
    <v-row no-gutters class="px-8 py-4" v-if="$vuetify.breakpoint.smAndUp">
      <v-col class="pr-12 d-flex justify-end" cols="5">
        <v-img
          src="@/assets/images/photo-3.jpg"
          lazy-src="@/assets/images/photo-3.jpg"
          alt="photo de la commanderie des templiers 2"
          height="300"
          contain
        />
      </v-col>
      <v-col class="d-flex text-justify align-center home-text" cols="7">
        <p>
          D'une superficie de 15 hectares et entièrement clôturée, la résidence
          la Commanderie des Templiers II qui a ses propres règles d'urbanisme
          comprend 290 maisons individuelles. Les parties communes, les terrains
          et équipements d'intérêt collectif (voirie, espaces verts, tennis,
          télévision, éclairage public, assainissement etc.,) sont la propriété
          exclusive de l'Association foncière urbaine libre (AFUL) "La
          Commanderie des Templiers II" qui en assure la gestion et l'entretien.
        </p>
      </v-col>
    </v-row>
    <div v-else class="d-flex flex-column ma-6">
      <v-img
        src="@/assets/images/photo-3.jpg"
        lazy-src="@/assets/images/photo-3.jpg"
        alt="photo de la commanderie des templiers 2"
        height="300"
        contain
      />
      <p>
        D'une superficie de 15 hectares et entièrement clôturée, la résidence la
        Commanderie des Templiers II qui a ses propres règles d'urbanisme
        comprend 290 maisons individuelles. Les parties communes, les terrains
        et équipements d'intérêt collectif (voirie, espaces verts, tennis,
        télévision, éclairage public, assainissement etc.,) sont la propriété
        exclusive de l'Association foncière urbaine libre (AFUL) "La Commanderie
        des Templiers II" qui en assure la gestion et l'entretien.
      </p>
    </div>
    <v-row v-if="$vuetify.breakpoint.smAndUp" no-gutters class="px-8 py-4">
      <v-col cols="7" class="d-flex text-justify align-center home-text">
        <p>
          L'AFUL "La Commanderie des Templiers II" est une association syndicale
          libre (ASL) soumise à l'ordonnance n°2004-632 du 1er juillet 2004.
          dont chaque propriétaire est membre de plein droit.
        </p>
      </v-col>
      <v-col class="pl-12 d-flex justify-end" cols="5">
        <v-img
          src="@/assets/images/photo-4.jpg"
          lazy-src="@/assets/images/photo-4.jpg"
          alt="photo de la commanderie des templiers 2"
          height="300"
          contain
        />
      </v-col>
    </v-row>
    <div v-else class="d-flex flex-column ma-6">
      <v-img
        src="@/assets/images/photo-4.jpg"
        lazy-src="@/assets/images/photo-4.jpg"
        alt="photo de la commanderie des templiers 2"
        height="300"
        contain
      />
      <p>
        L'AFUL "La Commanderie des Templiers II" est une association syndicale
        libre (ASL) soumise à l'ordonnance n°2004-632 du 1er juillet 2004. dont
        chaque propriétaire est membre de plein droit.
      </p>
    </div>
    <v-row v-if="$vuetify.breakpoint.smAndUp" no-gutters class="px-8 py-4">
      <v-col class="pr-12 d-flex justify-end" cols="5">
        <v-img
          src="@/assets/images/photo-1.jpg"
          lazy-src="@/assets/images/photo-1.jpg"
          alt="photo de la commanderie des templiers 2"
          height="300"
          contain
        />
      </v-col>
      <v-col cols="7" class="d-flex text-justify align-center home-text">
        <p>
          Cette résidence est idéalement bien située. Elle se trouve à proximité
          d’un petit centre commercial, des garderies et écoles, de l’hôpital de
          l’Ouest-Parisien, des services de transport en commun, des grands axes
          de circulation et à 40 mn. de Paris.
        </p>
      </v-col>
    </v-row>
    <div v-else class="d-flex flex-column ma-6">
      <v-img
        src="@/assets/images/photo-1.jpg"
        lazy-src="@/assets/images/photo-1.jpg"
        alt="photo de la commanderie des templiers 2"
        height="300"
        contain
      />
      <p>
        Cette résidence est idéalement bien située. Elle se trouve à proximité
        d’un petit centre commercial, des garderies et écoles, de l’hôpital de
        l’Ouest-Parisien, des services de transport en commun, des grands axes
        de circulation et à 40 mn. de Paris.
      </p>
    </div>
    <v-row no-gutters class="px-8 py-4" v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="7" class="d-flex text-justify align-center home-text">
        <p>
          Les nuisances sonores sont quasi inexistantes puisque la circulation
          automobile dans la résidence est limitée aux riverains. L'AFUL "La
          Commanderie des Templiers II" répartit ses dépenses entre ses 290
          membres et assure le recouvrement des cotisations destinées à
          l'entretien des espaces et équipements communs.
        </p>
      </v-col>
      <v-col class="pl-12 d-flex justify-end" cols="5">
        <v-img
          src="@/assets/images/photo-5.jpg"
          lazy-src="@/assets/images/photo-5.jpg"
          alt="photo de la commanderie des templiers 2"
          height="300"
          contain
        />
      </v-col>
    </v-row>
    <div v-else class="d-flex flex-column ma-6">
      <v-img
        src="@/assets/images/photo-5.jpg"
        lazy-src="@/assets/images/photo-5.jpg"
        alt="photo de la commanderie des templiers 2"
        height="300"
        contain
      />
      <p>
        Les nuisances sonores sont quasi inexistantes puisque la circulation
        automobile dans la résidence est limitée aux riverains. L'AFUL "La
        Commanderie des Templiers II" répartit ses dépenses entre ses 290
        membres et assure le recouvrement des cotisations destinées à
        l'entretien des espaces et équipements communs.
      </p>
    </div>
  </v-sheet>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "Home",
  components: {
    VideoPlayer,
  },
  mounted() {
    this.titleAnimation();
  },
  methods: {
    titleAnimation() {
      let text = document.getElementById("title");
      let wordList = text.getElementsByTagName("span");
      let i = 0;
      setInterval(function () {
        wordList[i].style.display = "none";
        i = (i + 1) % wordList.length;
        wordList[i].style.display = "initial";
        wordList[i].classList.add("animated", "fadeIn");
      }, 6000);
    },
  },
};
</script>

<style scoped>
.title-animation {
  z-index: 100;
  top: 12rem;
  position: absolute;
  color: #eee;
  letter-spacing: -2px;
  font-weight: 400;
  font-family: source sans pro, sans-serif !important;
}
.title-animation-mobile {
  left: 2rem;
  font-size: 60px;
}
.title-animation-desktop {
  right: 5rem;
  font-size: 100px;
}

h2 span {
  display: none;
  animation-iteration-count: infinite;
  animation-duration: 7s !important;
}

.first-word {
  display: initial;
}

.home-video,
.home-image {
  object-fit: cover;
  opacity: 0.7;
  width: 100vw;
  height: 100vh;
  border: none;
}
.container-accueil {
  position: relative;
  height: 100vh;
  background: black !important;
}
.home-arrow {
  z-index: 100;
  position: absolute;
  cursor: pointer;
  animation-iteration-count: infinite;
  animation-duration: 1.5s !important;
}
.arrow-desktop {
  bottom: 3rem;
  left: 50%;
  text-decoration: none;
  color: white;
}
.arrow-mobile {
  top: 73vh;
  left: 50%;
}
.home-text {
  font-family: source sans pro, sans-serif !important;
  font-size: 18px;
}
</style>
