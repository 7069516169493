<template>
  <div>
    <video ref="videoPlayer" class="home-video" autoplay="true" muted="true" loop="true">
      <source
        src="@/assets/videos/intro_aful2_1080p.webm"
        type="video/webm"
      />
      <source
        src="@/assets/videos/intro_aful2_1080p.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</template>

<style>
.home-video,
.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

<script>
export default {
  name: "VideoPlayer"
}
</script>
