import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import Contact from '@/views/Contact.vue'
import About from '@/views/About.vue'
import Infos from '@/views/Infos.vue'

// Pages onglet "Vie Locale"
import Commerces from '@/views/VieLocale/Commerces.vue'
import Transports from '@/views/VieLocale/Transports.vue'
import Loisirs from '@/views/VieLocale/Loisirs.vue'
import Ecoles from '@/views/VieLocale/Ecoles.vue'
import Dechets from '@/views/VieLocale/Dechets.vue'

export default [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: NotFound,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/a-propos',
    name: 'a-propos',
    component: About,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/infos-pratiques',
    name: 'Infos',
    component: Infos,
    meta: {
      layout: 'default',
    },
  },
  // Vie Locale
  {
    path: '/commerces',
    name: 'Commerces',
    component: Commerces,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/transports',
    name: 'Transports',
    component: Transports,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/loisirs-sports',
    name: 'Loisirs',
    component: Loisirs,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/ecoles-jeunesse',
    name: 'Ecoles',
    component: Ecoles,
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/gestion-des-dechets',
    name: 'Dechets',
    component: Dechets,
    meta: {
      layout: 'default',
    },
  },
]