<template>
  <container-banner title="Ecoles & jeunesse">
    <p>
      Crèches, écoles maternelle et primaire (publiques et privées) sont à proximité immédiate (5 à 10 mn à pied).
    </p>
    <h3 class="pt-8 mb-4">
      Petite enfance
    </h3>
    <p>
      Sur la commune d'Elancourt, plusieurs moyens de garde sont prévus pour accueillir les tout petit.
      On retrouve sur l'ensemble de la commune plusieurs crèches. Il est possible de retrouver l'ensemble des structures de la ville ici :<br>
      <span class="d-flex mt-4 mb-8">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link" 
          href="https://elancourt.fr/mon-quotidien/petite-enfance/les-structures-daccueil"
          target="_blank"
        >
            https://elancourt.fr/mon-quotidien/petite-enfance/les-structures-daccueil
        </a>
      </span>
      Si vous souhaitez avoir recours aux services d'une assistante maternelle, vous pouvez retrouver les disponilités de celles-ci directement sur le site de la mairie :<br>
      <span class="d-flex mt-4">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link" 
          href="https://elancourt.fr/plan-du-sitemap-who/famille/disponibilite-des-assistantes-maternelles"
          target="_blank"
        >
          https://elancourt.fr/plan-du-sitemap-who/famille/disponibilite-des-assistantes-maternelles
        </a>
      </span>
    </p>
    <h3 class="pt-8 mb-4">
      Ecoles maternelle et primaire
    </h3>
    <p>
      Les enfants de la Commanderie des Templiers 2, dépendent pour leur scolarisation (secteur public), des écoles maternelle et primaire Commanderie des Templiers situées toutes deux Avenue de la Villedieu.
    </p>
    <h3 class="pt-8 mb-4">
      Périscolaire
    </h3>
    <p>
      En dehors des heures de scolarisation, le mercredi ou durant les vacances scolaires, la ville d'Elancourt mets en place un accueil périscolaire. Pour les enfants scolarisés à l'école maternelle ou primaire de la Commanderie, cet accueil se fait à l'accueil de loisirs maternel Jean-Claude Bernard.<br>
      Pour plus d'informations :<br>
      <span class="d-flex mt-4">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link" 
          href="https://elancourt.fr/mon-quotidien/enfance-education/les-accueils-de-loisirs"
          target="_blank"
        >
          https://elancourt.fr/mon-quotidien/enfance-education/les-accueils-de-loisirs
        </a>
      </span>
    </p>
    <h3 class="pt-8 mb-4">
      Collèges et Lycées
    </h3>
    <p>
      Nous avons également le collège de l'Agiot, le lycée des 7 Mares ainsi que le lycée Dumont d'Urville accessibles entre 15 à 20 mn en bus.
    </p>
    <h3 class="pt-8 mb-4">
      Université
    </h3>
    <p>
      Concernant l'Université de Versailles-Saint-Quentin, toutes les informations sont disponibles directement sur le site de l'UVSQ :<br>
      <span class="d-flex mt-4">
        <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
        <a 
          class="extern-link" 
          href="https://www.uvsq.fr/"
        >
          https://www.uvsq.fr/
        </a>
      </span>
    </p>
  </container-banner>
</template>

<script>
import ContainerBanner from '@/components/ContainerBanner.vue'

export default {
  name: 'Ecoles',
  components: {
    ContainerBanner
  }
}
</script>