<template>
  <div class="mapouter">
    <div class="gmap_canvas">
      <iframe
        :width="mapsWidth"
        :height="mapsHeight"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=40%20bis%20avenue%20de%20Beaujeu%20elancourt&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maps",
  props: {
    mapsWidth: {
      type: String,
      default: "786",
    },
    mapsHeight: {
      type: String,
      default: "518",
    },
  },
};
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
}
</style>