<template>
  <div>
    <v-row
      v-if="
        $vuetify.breakpoint.mdOnly ||
        $vuetify.breakpoint.lgOnly
      "
    >
      <v-col cols="8">
        <Maps class="maps-desktop" />
      </v-col>
      <v-col cols="4">
        <h2 class="title contact-desktop">
          AFUL - La Commanderie des Templiers II
        </h2>
        <Address />
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xlOnly">
      <v-col cols="6">
        <Maps
          class="maps-desktop"
          maps-width="900"
          maps-height="700"
        />
      </v-col>
      <v-col cols="6">
        <h2 class="title contact-desktop">
          AFUL - La Commanderie des Templiers II
        </h2>
        <Address />
      </v-col>
    </v-row>
    <div
      v-if="
        $vuetify.breakpoint.xsOnly ||
        $vuetify.breakpoint.smOnly
      "
      class="d-flex flex-column"
    >
      <h2 class="title contact-mobile">
        AFUL - La Commanderie des Templiers II
      </h2>
      <Address />
      <Maps
        class="pa-6"
        maps-height="400"
        maps-width="100%"
      />
    </div>
  </div>
</template>

<script>
import Maps from "@/components/Maps.vue";
import Address from "@/components/Address.vue";

export default {
  name: "Contact",
  components: {
    Maps,
    Address,
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  color: #444;
  line-height: 1.5;
  font-family: source sans pro, sans-serif !important;
}
.contact-desktop {
  padding: 6rem 4rem;
}
.contact-mobile {
  padding: 4rem 4rem 2rem;
}
.maps-desktop {
  padding: 6rem 2rem;
}
</style>
