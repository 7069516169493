<template>
  <div class="contact-container">
    <address class="contact-item">
      <span class="name-address-item">Adresse postale :</span><br>
      40 bis avenue de Beaujeu<br>
      78990 Élancourt<br>
    </address>
    <div class="contact-item">
      <span class="name-address-item">Numéro téléphone AFUL2 :</span><br>
      <span>06 72 94 03 94</span>
    </div>
    <div class="contact-item">
      <span class="name-address-item">E-mail :</span><br>
      <a 
        href="mailto:afulct2@gmail.com" 
        class="address-mail"
      > 
        afulct2@gmail.com
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Address"
}
</script>

<style scoped>
.contact-container {
  padding: 2rem 4rem;
  font-family: source sans pro, sans-serif !important;
}
.contact-item {
  margin-bottom: 30px;
}
.name-address-item {
  font-weight: bold;
  line-height: 1.7;
  font-size: 18px;
  font-family: source sans pro, sans-serif !important;
  color: #555;
}
address {
  font-style: normal !important;
}
.address-mail {
  color: inherit !important;
}
</style>