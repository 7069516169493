<template>
  <v-card
    outlined
    width="309"
    class="mb-8 d-flex flex-column"
    tile
    max-height="460px"
  >
    <v-img
      :src="require(`@/assets/images/${filename}`)"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
      height="170px"
      max-height="170px"
      alt="image d'illustration du site internet présenté"
    />
    <v-card-title class="card-title">
      {{ title }}
    </v-card-title>
    <v-card-text class="mb-2 card-text">
      {{ text }}
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-text class="align-end mt-2 card-text">
      <a 
        :href="link"
        class="extern-link pl-3"
        target="_blank"
      >
        Accéder au site
      </a> 
      <v-icon color="#1ABC9C">mdi-menu-right</v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>
  .extern-link {
    color: #1abc9c;
    font-weight: 600;
  }
  .card-title {
    font-size: 20px;
    color: #444;
    line-height: 1.5;
    font-family: source sans pro, sans-serif !important;
    font-weight: 600;
  }
  .card-text {
    font-family: source sans pro, sans-serif !important;
    font-size: 18px;
  }
</style>